import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    content: false,
  },
  getters: {
    getContent(state) {
      return state.content
    }
  },
  mutations: {
    setContent(state) {
      state.content = true
    }
  },
  actions: {
    doContent(context) {
      context.commit('setContent')
    }
  },
  modules: {}
})
