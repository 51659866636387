<template>
  <div id="app" v-bind:class="[{ active: mobile }, { load: showContent }]">

    <router-view/>

    <div class="loader-wrapper" v-if="!showContent">
      <div v-show="showLoader === 1" ref="loader1" class="loader">

        <div class="back">
          <img class="desk" src="/img/loader/back.png" alt="">

          <picture>
            <source srcSet="/img/loader/back-mob-mobile.jpg" media="(max-width: 550px)">
            <img class="mob" src="/img/loader/back-mob.jpg" alt="">
          </picture>

        </div>

        <div class="right">

          <div class="img-wrapper">
            <img class="text-img desk" src="/img/loader/text1.png" alt="">

            <picture>
              <source srcSet="/img/loader/text1-mob-mobile.png" media="(max-width: 550px)">
              <img class="text-img mob" src="/img/loader/text1-mob.png" alt="">
            </picture>

          </div>

          <p class="text">означає брати під контроль власне життя та здоров’я</p>
        </div>

      </div>

      <div v-show="showLoader === 2" ref="loader2" class="loader2">
        <div class="bg">
          <picture>
            <source srcSet="/img/loader2/bg1-mobile.jpg" media="(max-width: 550px)">
            <source srcSet="/img/loader2/bg1-tablet.jpg" media="(max-width: 960px)">
            <img src="/img/loader2/bg1.jpg" alt="">
          </picture>

        </div>
        <div class="center">
          <picture>
            <source srcSet="/img/loader2/center-mobile.png" media="(max-width: 550px)">
            <source srcSet="/img/loader2/center-tablet.png" media="(max-width: 960px)">
            <img class="border" src="/img/loader2/center.png" alt="">
          </picture>

        </div>
        <div class="center2">
          <picture>
            <source srcSet="/img/loader2/text-mobile.png" media="(max-width: 960px)">
            <source srcSet="/img/loader2/text-tablet.png" media="(max-width: 550px)">
            <img class="text" src="/img/loader2/text.png" alt="">
          </picture>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {gsap, TweenMax, TimelineMax} from 'gsap';
import {mapActions} from 'vuex'

export default {
  name: "app",

  data: () => ({
    loader: new TimelineMax({delay: 1}),
    mobile: false,
    showLoader: 1,
    showContent: false
  }),

  mounted() {
    let vh = window.innerHeight * 0.01;

    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener("resize", () => {

      let vh = window.innerHeight * 0.01;

      document.documentElement.style.setProperty('--vh', `${vh}px`);

    });

    if (window.matchMedia("(max-width: 500px)").matches) {
      this.loader
          .to('.loader .back, .loader .img-wrapper', .2, {opacity: 1})
          .to('.loader .text', .2, {opacity: 1})
          .to('.loader .back, .loader .img-wrapper, .loader .text', .2, {opacity: 0, delay: 1.5})
          .add(() => {
            this.showLoader = 0
            this.showContent = !this.showContent

            this.doContent()
            document.body.style.overflowY = "visible";
          })
    } else {
      this.loader
          .to('.loader .back, .loader .img-wrapper', .2, {opacity: 1})
          .to('.loader .text', .2, {opacity: 1})
          .to('.loader .back, .loader .img-wrapper, .loader .text', .2, {opacity: 0, delay: 1.5})
          .add(() => {
            this.showLoader = 2
          })
          .to('.loader2 .bg img, .loader2 .center img', .2, {opacity: 1})
          .to('.loader2 .center2 img', .2, {opacity: 1})
          .to('.loader2 .bg img, .loader2 .center img, .loader2 .center2 img', .2, {opacity: 0, delay: .5})
          .add(() => {
            this.showLoader = 0
            this.showContent = !this.showContent

            this.doContent()
            document.body.style.overflowY = "visible";
          })
    }


    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
      this.mobile = true;
    }

    if (/android/i.test(userAgent)) {
      this.mobile = true;
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      this.mobile = true;
    }

    return "unknown";


  },

  methods: {
    ...mapActions([
      'doContent'
    ])
  }

}
</script>

<style lang="scss">

@import "@/assets/style/fonts.css";
@import "@/assets/style/vars";

body {
  background: #19183D;
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
}

* {
  user-select: none
}

#app {

  .home {
    z-index: -1;

    .main-content {
      z-index: -1;
      padding-top: 1px;
    }
  }

  &.load {
    .home {
      z-index: 1;

      .main-content {
        z-index: 1;
      }
    }
  }

  .loader-wrapper {
    z-index: 10000000;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;

    .loader {
      min-height: calc(100vh);
      max-height: calc((var(--vh, 1vh) * 100));
      width: 100%;
      height: 100%;

      position: relative;
      z-index: 10000000;

      background: #1E1E3F;

      display: flex;
      justify-content: flex-end;

      transition: all 1s ease;

      @media screen and (max-width: 768px) {
        justify-content: flex-start;
        align-items: flex-start;
      }

      .back {
        max-width: 100%;
        width: 100%;
        position: absolute;

        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        z-index: 1;
        display: flex;
        align-items: center;

        opacity: 0;

        @media screen and (max-width: 768px) {

          max-width: 80%;

          top: 0;
          left: auto;
          right: 0;
          bottom: 0;

          align-items: start;

        }

        @media screen and (max-width: 425px) {
          max-width: 100%;
        }

        img {
          width: 100%;
          height: auto;
          display: block;
          object-fit: contain;

          &.mob {
            display: none;
            @media screen and (max-width: 768px) {
              display: block;
            }
          }

          &.desk {
            @media screen and (max-width: 768px) {
              display: none;
            }
          }
        }
      }

      .right {
        position: relative;
        z-index: 2;

        max-width: 62.3%;
        width: 100%;
        margin-top: 29vh;

        @media screen and (max-width: 768px) {
          margin: auto auto 20vh auto;

        }
        @media screen and (max-width: 425px) {
          max-width: 90%;
        }

        .img-wrapper {
          max-width: 691px;
          width: 100%;
          position: relative;
          opacity: 0;

          @media screen and (max-width: 768px) {
            display: block;
            max-width: 547px;
          }

          @media screen and (max-width: 425px) {
            max-width: 80%;
          }

          .text-img {
            width: 100%;

            height: auto;
            position: relative;
            margin-left: -5.5%;

            @media screen and (max-width: 768px) {
              margin-left: 0;
            }

            &.mob {
              display: none;
              @media screen and (max-width: 768px) {
                display: block;
              }
            }

            &.desk {
              @media screen and (max-width: 768px) {
                display: none;
              }
            }

          }
        }

        .text {
          opacity: 0;
          color: #B7B7E5;

          text-shadow: 0 0 71px rgba(49, 144, 255, 0.5);
          font-family: $medium;

          font-size: 28px;
          line-height: 130%;

          max-width: 526px;
          width: 100%;
          margin-top: -40px;

          @media screen and (max-width: 768px) {
            font-size: 24px;
            margin-top: -20px;
            line-height: 31px;
            padding-left: 5%;
          }

          @media screen and (max-width: 425px) {
            font-size: 20px;
            filter: blur(0);
            margin-top: 0;

            line-height: 27.2px;
          }
        }
      }

      .scroll-icon {
        visibility: hidden;
        position: absolute;
        z-index: 2;
        bottom: 27px;
        right: 0;
        left: 0;

        display: flex;
        justify-content: center;
        animation: scroll-down-pulse 3s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite;


        .desk {
          @media screen and (max-width: 768px) {
            display: none;
          }
        }

        .mob {
          display: none;
          @media screen and (max-width: 768px) {
            display: block;
          }
        }
      }
    }

    .loader2 {
      min-height: calc(100vh);
      max-height: calc((var(--vh, 1vh) * 100));
      width: 100%;
      height: 100%;

      position: relative;
      z-index: 10000000;

      background: #1E1E3F;

      padding-bottom: 20px;

      .bg {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        z-index: 2;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: block;
          opacity: 0;

          @media screen and (max-width: 768px) {
            height: 130%;
            width: auto;
          }
        }
      }

      .center {
        position: absolute;
        z-index: 4;
        width: 100%;
        height: 100%;

        margin: auto;

        display: flex;
        justify-content: center;
        align-items: center;

        picture {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        img {
          max-width: 54%;
          width: 100%;
          height: auto;
          display: block;
          opacity: 0;

          @media screen and (max-width: 768px) {
            max-width: 100%;
          }
        }
      }

      .center2 {
        position: absolute;
        z-index: 3;
        width: 100%;
        height: 100%;

        margin: auto;

        display: flex;
        justify-content: center;
        align-items: center;

        picture {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        img {
          max-width: 42%;
          width: 100%;
          height: auto;
          display: block;
          opacity: 0;

          @media screen and (max-width: 768px) {
            max-width: 78%;
          }
        }
      }
    }
  }

  &.active {
    * {
      outline: none !important;
      cursor: none !important;
    }
  }
}

</style>
